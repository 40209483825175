// @flow
import * as React from 'react'
import { getStore } from '../store'


export const withStore = (Component: React.ComponentType<Object>) => {
  return function StoreComponent (props: Object) {
    return <Component {...props} store={getStore()} />
  }
}
