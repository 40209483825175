// @flow
import React, { Component } from 'react'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import { Mutation } from 'react-apollo'
import { NoScroll } from '@samplar/web-utils/scroll'
import Header from '../components/Header'
import ResetPasswordForm from '../forms/ResetPasswordForm'
import ResetPasswordSuccess from '../forms/ResetPasswordSuccess'
import styles from './Form.css'

import { RESET_PASSWORD } from '../mutations'

import type {
  Location
} from 'react-router-dom'


type ResetPasswordProps = {
  location: Location,
}

class ResetPassword extends Component<ResetPasswordProps> {

  render () {
    const { location } = this.props
    const { token } = queryString.parse(location.search)

    return (
      <div className={styles.component}>
        <NoScroll />
        <Header
          className={styles.header} />
        <div className={styles.container}>
          <h1 className={styles.title}>
            Reset Password
          </h1>
          <Mutation
            mutation={RESET_PASSWORD}
            errorPolicy='ignore'>
            {(mutate, props) => {

              if (props.data) {
                return <ResetPasswordSuccess data={props.data} />
              }

              return (
                <ResetPasswordForm
                  mutate={mutate}
                  token={token}
                  {...props} />
              )
            }}
          </Mutation>
        </div>
      </div>
    )
  }

}

export default withRouter(ResetPassword)
