// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import BrandLink from '@samplar/web-ui/links/BrandLink'
import CloseButton from '@samplar/web-ui/buttons/CloseButton'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import RegisterForm from '../forms/RegisterForm'
import RegisterSuccess from '../forms/RegisterSuccess'
import styles from './Register.css'

import type {
  Location,
  RouterHistory
} from 'react-router-dom'


type RegisterProps = {
  history: RouterHistory,
  location: Location,
}


class Register extends Component<RegisterProps> {

  isModal (): boolean {
    const { location } = this.props
    if (location
        && location.state
        && location.state.modal) {
      return true
    }
    return false
  }

  goBack = () => {
    if (this.isModal()) {
      this.props.history.goBack()
      return
    }
    this.props.history.push('/')
  }

  render () {
    const url = 'https://samplar.us18.list-manage.com/subscribe/post?u=a5f9e79d883b5364ae99024db&amp;id=cde53deb5d'
    return (
      <div className={styles.component}>
        <div className={styles.header}>
          <BrandLink
            className={styles.brand}/>
          <CloseButton
            className={styles.close}
            onClick={this.goBack} />
        </div>
        <div className={styles.container}>
          <h1 className={styles.title}>
            Register
          </h1>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => {

              if (status === 'success') {
                return <RegisterSuccess />
              }

              return (
                <RegisterForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)} />
              )
            }} />
        </div>
      </div>
    )
  }

}

export default withRouter(Register)
