// @flow
import React from 'react'
import styles from './CircleLoader.css'


type CircleLoaderProps = {
  className?: string,
  theme?: 'light' | 'red' | 'dark',
  size?: 'small',
}

const CircleLoader = ({ className, theme, size }: CircleLoaderProps) => {

  let restyle = theme === 'light'
    ? `${styles.component} ${styles.light}`
    : styles.component

  restyle = className
    ? `${restyle} ${className}`
    : restyle

  restyle = size
    ? `${restyle} ${styles[size]}`
    : restyle

  return (
    <div className={restyle} />
  )
}

export default CircleLoader
