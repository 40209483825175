// @flow
import React, { Component } from 'react'


type MutateOnMountProps = {
  mutate: Function,
}

class MutateOnMount extends Component<MutateOnMountProps> {

  componentDidMount () {
    this.props.mutate()
  }

  render () {
    return (<div />)
  }

}

export default MutateOnMount
