// @flow
import React, { Component } from 'react'
import { Form } from '@samplar/web-form'
import TextField from '@samplar/web-ui/inputs/TextField'
import LoaderAnimation from '@samplar/web-ui/loaders/CircleLoader'
import TextButton from '../components/TextButton'
import TextLink from '../components/TextLink'
import Errors from '@samplar/web-ui/errors/FormErrors'
import styles from './Form.css'

import {
  validateEmail,
  validateUsername,
  validatePassword,
  validatePasswordStrength
} from '@samplar/web-form/validators'


type SignUpFormProps = {
  error?: Object,
  loading?: boolean,
  data?: Object,
  mutate: Function,
}

type SignUpInput = {
  email: string,
  username: string,
  password: string,
  confirmPassword: string,
}

class SignUpForm extends Component<SignUpFormProps> {

  onSubmit = async ({ email, username, password }: SignUpInput) => {
    const register = {
      email,
      username,
      password
    }
    try {
      const { mutate } = this.props
      await mutate({ variables: { register } })
    }
    catch (err) {
      console.log('GraphQL Error: ', err.message)
    }
  }

  render () {
    const { loading, error } = this.props

    return (
      <div className={styles.component}>

        <Form onSubmit={this.onSubmit}>
          {({ formApi, formState }) => {

            return (
              <div>
                <TextField
                  field={'username'}
                  label={'username'}
                  placeholder={'username'}
                  theme={'light'}
                  validate={validateUsername}
                  validateOnBlur />

                <TextField
                  field={'email'}
                  label={'email'}
                  placeholder={'email'}
                  theme={'light'}
                  validate={validateEmail}
                  validateOnBlur />

                <TextField
                  field={'password'}
                  label={'password'}
                  placeholder={'password'}
                  theme={'light'}
                  type={'password'}
                  validate={validatePasswordStrength}
                  validateOnBlur />

                <TextField
                  field={'confirmPassword'}
                  label={'confirm password'}
                  placeholder={'password'}
                  theme={'light'}
                  type={'password'}
                  notify={['password']}
                  validate={validatePassword}
                  validateOnBlur />

                {formState.errors
                  && <Errors
                    errors={formState.errors}
                    message={'Sorry, there are a few changes required:'}
                    theme={'light'} />
                }

                {error
                  && error.graphQLErrors
                  && <Errors
                    errors={error.graphQLErrors}
                    message={'Your registration failed, please check the following:'}
                    theme={'light'} />
                }

                {loading
                  && <LoaderAnimation
                    theme={'light'}
                    size={'small'} />
                }

                { ! loading
                  && <div className={styles.actionsPrimary}>
                    <TextButton
                      mode={'primary'}
                      onClick={formApi.submitForm}>
                      Register
                    </TextButton>
                    <TextLink
                      type={'subtle'}
                      to={'/login'}>
                      I&apos;m already a member
                    </TextLink>
                  </div>
                }
              </div>
            )
          }}

        </Form>
      </div>
    )
  }

}

export default SignUpForm
