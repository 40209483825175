// @flow
import React from 'react'
import Button from '@samplar/web-ui/buttons/TextButton'
import styles from './TextButton.css'

import type {
  TextButtonProps
} from '@samplar/web-ui/buttons/TextButton'


const TextButton = (props: TextButtonProps) => {

  return (
    <Button
      className={styles[props.mode]}
      {...props} />
  )
}

export default TextButton
