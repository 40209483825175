// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import SVG from './assets/back.svg'
import styles from './BackButton.css'


type BackButtonProps = {
  className?: string,
  history: Object,
}


class BackButton extends Component<BackButtonProps> {

  onClick = () => {
    this.props.history.goBack()
  }

  render () {
    const { className } = this.props

    let restyle = className
      ? `${styles.component} ${className}`
      : styles.component

    return (
      <button
        onClick={this.onClick}
        className={restyle}>
        <span className={styles.hidden}>Back</span>
        <SVG />
      </button>
    )
  }

}

export default withRouter(BackButton)
