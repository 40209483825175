// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Mutation } from 'react-apollo'
import LoaderAnimation from '@samplar/web-ui/loaders/CircleInlineLoader'
import styles from './LogoutButton.css'

import { USER_LOGOUT } from '../mutations'

import type {
  Node
} from 'react'

import type {
  RouterHistory
} from 'react-router-dom'


type LogoutButtonProps = {
  children?: Node,
  className?: string,
  history: RouterHistory,
}

class LogoutButton extends Component<LogoutButtonProps> {

  onLogout = async (mutate: Function, client: Object) => {
    try {
      await mutate({ variables: { modifier: 'single' } })
      localStorage.removeItem('auth-token')
      client.resetStore()
      this.props.history.push('/')
    }
    catch (err) {
      console.log('Logout Error: ', err.message)
    }
  }

  render () {
    const { children, className } = this.props

    let restyle = className
      ? `${styles.component} ${className}`
      : styles.component

    return (
      <Mutation
        mutation={USER_LOGOUT}
        errorPolicy='ignore'>
        {(mutate, { loading, client }) => {
          return (
            <button
              className={restyle}
              onClick={() => this.onLogout(mutate, client)}>
              <span>{children}</span>
              {loading
                && <span className={styles.loader}>
                  <LoaderAnimation size={'small'} />
                </span>
              }
            </button>
          )
        }}
      </Mutation>
    )
  }

}

export default withRouter(LogoutButton)
