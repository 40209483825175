// @flow
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import introspectionQueryResultData from './fragmentTypes.json'


type ClientOptions = {
  uri: string,
}

const createClient = ({ uri }: ClientOptions): ApolloClient => {

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })

  const cache = new InMemoryCache({ fragmentMatcher })

  const httpLink = new HttpLink({ uri })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('auth-token')
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      }
    }
  })

  const link = authLink.concat(httpLink)

  return new ApolloClient({
    cache,
    link,
  })
}

export default createClient
