// @flow
import React from 'react'
import { withTheme } from './Theme'

import type {
  Theme
} from '../types'


type BodyThemeProps = {
  theme: Theme,
}

const classNames = [
  'isLightTheme',
  'isDarkTheme',
  'isHighContrast',
  'isNormalContrast'
]

const BodyTheme = ({ theme }: BodyThemeProps) => {

  // $FlowIgnore
  document.body.classList.remove(...classNames)

  const mode = `is${theme.mode}Theme`
  const contrast = `is${theme.contrast}Contrast`

  // $FlowIgnore
  document.body.classList.add(mode, contrast)

  return (<div />)
}

export default withTheme(BodyTheme)
