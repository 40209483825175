import gql from 'graphql-tag'


export const USER_REGISTER = gql`
  mutation UserRegister($register: UserRegisterInput) {
    userRegister(input: $register) {
      email
      username
    }
  }
`

export const USER_LOGIN = gql`
  mutation UserLogin ($credentials: AuthLoginInput) {
    authLogin (input: $credentials) {
      verified
      token
    }
  }
`

export const USER_LOGOUT = gql`
  mutation UserLogout($modifier: String) {
    authLogout(modifier: $modifier)
  }
`

export const VERIFY_EMAIL = gql`
  mutation UserVerifyEmail ($token: UserVerifyEmailInput) {
    userVerifyEmail(input: $token) {
      email
      verified
      primary
    }
  }
`

export const FORGOTTEN_PASSWORD = gql`
  mutation UserRequestPasswordReset($credential: UserRequestPasswordResetInput) {
    userRequestPasswordReset(input: $credential)
  }
`

export const RESET_PASSWORD = gql`
  mutation UserResetPassword($reset: UserResetPasswordInput) {
    userResetPassword(input: $reset)
  }
`
