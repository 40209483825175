// @flow
import * as React from 'react'
import { ThemeConsumer } from '../Context'


export const withTheme = (Component: React.ComponentType<Object>) => {
  return function ThemedComponent (props: Object) {
    return (
      <ThemeConsumer>
        {theme => <Component {...props} theme={theme} />}
      </ThemeConsumer>
    )
  }
}
