// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './CalloutButton.css'

import type {
  Node
} from 'react'


type CalloutButtonProps = {
  children: Node,
  to: string,
}

const CalloutButton = ({ children, to }: CalloutButtonProps) => (
  <Link
    className={styles.component}
    to={to}>
    {children}
  </Link>
)

export default CalloutButton
