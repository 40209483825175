// @flow
import React from 'react'
import TextLink from '../components/TextLink'
import styles from './Form.css'


const ForgottenPasswordSuccess = () => (
  <div className={styles.component}>
    <h1 className={styles.title}>
      Done!
    </h1>
    <p>
      Your password has been reset.
      <br />
      <br />
      Consider keeping it somewhere safe like <a href='https://1password.com/' target='blanl'>1password.com</a>
    </p>
    <div className={styles.actionsPrimary}>
      <TextLink
        type={'primary'}
        to={'/login'}>
        Login
      </TextLink>
    </div>
  </div>
)

export default ForgottenPasswordSuccess
