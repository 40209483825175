// @flow
import React from 'react'
import styles from './SectionFour.css'


const SectionFour = () => (
  <div className={styles.component}>
    <div className={styles.gradient}></div>
    <div className={styles.container}>
      <h1 className={styles.title}>
        Reach<br />
        audiences<br />
        on the go.
      </h1>
      <p className={styles.info}>
        <span className={styles.bolder}>Samplar enables</span>&nbsp;
        you to&nbsp;
        <span className={styles.bolder}>reach across</span>&nbsp;
        <span className={styles.lgBreak}></span>
        <span className={styles.bolder}>all mediums</span>,
        so you can reach&nbsp;
        <span className={styles.lgBreak}></span>
        your audience&nbsp;
        <span className={styles.bolder}>anytime</span> on&nbsp;
        <span className={styles.bolder}>any device</span>.
      </p>
    </div>
  </div>
)

export default SectionFour
