// @flow
import React from 'react'
import TextLink from '../components/TextLink'
import styles from './Form.css'


const VerifyEmailFailure = () => (
  <div className={styles.component}>
    <h1 className={styles.title}>
      Failed
    </h1>
    <p>
      The email address has not been verified.
    </p>
    <div className={styles.actionsPrimary}>
      <TextLink
        type={'primary'}
        to={'/'}>
        Continue
      </TextLink>
    </div>
  </div>
)

export default VerifyEmailFailure
