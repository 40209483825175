// @flow
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import App from './App'
import Register from './modules/register'

import {
  ForgottenPassword,
  ResetPassword,
  SignIn,
  SignUp,
  VerifyEmail
} from '@samplar/web-login'

import type {
  Location,
  RouterHistory
} from 'react-router-dom'


type ModalSwitchProps = {
  history: RouterHistory,
  location: Location,
}

class ModalSwitch extends Component<ModalSwitchProps> {

  prevLocation: Location

  constructor (props) {
    super(props)
    this.prevLocation = props.location
  }

  UNSAFE_componentWillUpdate (nextProps) { // eslint-disable-line camelcase
    const { location } = this.props
    if (nextProps.history.action !== 'POP'
      && ( ! location.state || ! location.state.modal)) {
      this.prevLocation = this.props.location
    }
  }

  render () {
    const { location } = this.props
    const isModal = !! (
      location.state &&
      location.state.modal &&
      this.prevLocation !== location
    )

    return (
      <div>
        <Switch location={isModal ? this.prevLocation : location}>
          <Route path='/register' component={Register} />
          <Route path='/' component={App} />
        </Switch>
      </div>
    )
  }

}

const Main = () => (
  <Router>
    <Route component={ModalSwitch} />
  </Router>
)


export default Main
