// @flow
import React from 'react'
import CalloutButton from './CalloutButton'
import styles from './SectionOne.css'


const SectionOne = () => (
  <div className={styles.component}>
    <div className={styles.container}>
      <span className={styles.image}></span>
      <div className={styles.statement} />
      <h1 className={styles.title}>
        <span>
          Vloggers,
          bloggers &
          podcasters<br />
          - 3X your reach.
        </span>
      </h1>
      <div className={styles.secondary}>
        <p className={styles.info}>
          Publish across&nbsp;
          <span className={styles.bolder}>all mediums</span>
          &nbsp;from one place,&nbsp;
          <span className={styles.lgBreak}></span>
          so you can&nbsp;
          <span className={styles.bolder}>reach your audiences where they are</span>.
        </p>
        <CalloutButton
          to='/register'>
          Create your profile
        </CalloutButton>
      </div>
    </div>
  </div>
)

export default SectionOne
