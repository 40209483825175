import {
  applyMiddleware,
  compose,
  createStore
} from 'redux'

import thunk from 'redux-thunk'
import { makeRootReducer } from './reducers'


let store
const ASYNC_REDUCER_INJECTED = `async_reducer_injected`

export default (initialState = {}) => {
  const middleware = [thunk]
  const enhancers = []

  if (__DEV__) {
    const devToolsExtension = window.devToolsExtension

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  store = createStore(
    makeRootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  )

  store.asyncReducers = {}

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers)) // eslint-disable-line
    })
  }

  return store
}

export const injectAsyncReducer = (key, reducer) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return
  }

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
  store.dispatch({ type: ASYNC_REDUCER_INJECTED })
}

export const getStore = () => store
