// @flow

type PasswordConfirmationInput = {
  password?: string,
  confirmPassword?: string,
}

export const validateBasic = (value: string): ?string => {
  return ! value || value.length < 1
    ? `Please ensure you fill required fields.`
    : null
}

export const validateEmail = (value: string): ?string => {
  return ! value || value.length < 3 || value.indexOf('@') < 0
    ? `Please add a valid email address.`
    : null
}

export const validateUsername = (value: string): ?string => {
  let message = `Usernames must be between 3 - 25 characters in length and alphanumeric. `
  message += `They can contain the following character seperators: "-_".`
  return ! value || ! /^(?=.{3,25}$)[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/.test(value)
    ? message
    : null
}

export const validatePasswordStrength = (value: string): ?string => {
  let message = `Passwords must be between 10 - 50 characters in length `
  message += `and contain at least 2 letters, numbers and special characters.`
  const regex = new RegExp('^(?=(?:[^a-z]*[a-z]){2})(?=(?:[^0-9]*[0-9]){2})(?=(.*[!-\/:-@\[-`{-~]){2}).{10,50}$') // eslint-disable-line
  return ! value || ! regex.test(value)
    ? message
    : null
}

export const validatePasswordConfirmation = (values: PasswordConfirmationInput): ?string => {
  return values.password !== values.confirmPassword
    ? 'Please ensure both passwords match.'
    : null
}

export const validatePassword = (value: string, values: PasswordConfirmationInput): ?string => {
  return validatePasswordConfirmation(values)
    || validatePasswordStrength(value)
}
