// @flow
import React from 'react'
import styles from './FormErrors.css'


type GraphQLError = {
  message: string,
}

type ErrorsProps = {
  errors: { [string]: string } | Array<string | GraphQLError>,
  message: string,
  theme?: 'light' | 'dark',
}

const FormErrors = ({ errors, message, theme }: ErrorsProps) => {

  let errorList = []

  if (Array.isArray(errors)) {
    errorList = errors.map((error, i) => {
      const err = error.message
        ? String(error.message)
        : String(error)
      return err
    })
  }
  else {
    errorList = Object.keys(errors).map((key, i) =>
      errors[key])
  }

  if (errorList.length < 1) {
    return <div />
  }

  errorList = Array.from(new Set(errorList))
  errorList = errorList.map((err, i) =>
    <li key={i}>{err}</li>)

  const restyle = theme
    ? `${styles.component} ${styles.isLight}`
    : styles.component

  return (
    <div className={restyle}>
      <span className={styles.message}>
        {message}
      </span>
      <ul className={styles.errors}>
        {errorList}
      </ul>
    </div>
  )
}

export default FormErrors
