// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './SectionThree.css'


const SectionThree = () => (
  <div className={styles.component}>
    <div className={styles.container}>
      <h1 className={styles.title}>
        Build your platform.
      </h1>
      <p className={styles.info}>
        We’re for&nbsp;
        <span className={styles.bolder}>creators, makers, authors, educators</span>&nbsp;&&nbsp;
        <span className={styles.bolder}>journalists</span>.
        <br />
        <span className={styles.bolder}>Join now</span> and&nbsp;
        <span className={styles.bolder}>help shape</span> a platform for&nbsp;
        <span className={styles.bolder}>thought</span> and&nbsp;
        <span className={styles.bolder}>creativity</span>.
      </p>
      <div className={styles.details}>
        <div className={styles.detail}>
          <h2 className={styles.detailTitle}>Free &amp; Open</h2>
          <p className={styles.detailInfo}>
            Content available for anyone and everyone, anywhere in the world.
            <br />
            Grow your audience with free and open content.
          </p>
          <Link
            className={styles.detailLink}
            to='/register'>
            Learn More
          </Link>
        </div>
        <div className={styles.detail}>
          <h2 className={styles.detailTitle}>Premium</h2>
          <p className={styles.detailInfo}>
            Transform your premium content into an income by sharing it first with paid subscribers.
            <br />
            Monetize your content without being advertiser dependent.
          </p>
          <Link
            className={styles.detailLink}
            to='/register'>
            Learn More
          </Link>
        </div>
        <div className={styles.detail}>
          <h2 className={styles.detailTitle}>Enterprise</h2>
          <p className={styles.detailInfo}>
            Use our platform tools and API as the next generation content platform for your organisation.
            <br />
            Publish, grow reach and monetize your content publicly or privately across all consumer devices with ease.
          </p>
          <Link
            className={styles.detailLink}
            to='/register'>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default SectionThree
