import { combineReducers } from 'redux'


const isemptyreducer = () => ({})

export const makeRootReducer = asyncReducers =>
  combineReducers({
    ...asyncReducers,
    isemptyreducer,
  })

export default makeRootReducer
