// @flow
import React from 'react'
import styles from './TextButton.css'

import type { Node } from 'react'


export type TextButtonProps = {
  mode: 'subtle' | 'primary' | 'callout' | 'disabled',
  children?: Node,
  className?: string,
  onClick: Function,
  type?: 'button' | 'reset' | 'submit',
}

const modes = new Set(['subtle', 'primary', 'callout', 'disabled'])

const TextButton = ({
  children, mode, className, onClick, type = 'button'
}: TextButtonProps) => {

  let restyle = modes.has(mode)
    ? styles[mode]
    : styles.primary

  restyle = onClick
    ? restyle
    : styles.disabled

  restyle = className
    ? `${restyle} ${className}`
    : restyle

  onClick = onClick
    ? onClick
    : () => {}

  return (
    <button
      onClick={onClick}
      className={restyle}
      type={type}>
      {children}
    </button>
  )
}

export default TextButton
