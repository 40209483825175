// @flow
import * as React from 'react'

import type {
  Theme,
  ThemeMode,
  ThemeContrast
} from './types'


const defaultTheme: Theme = {
  mode: 'Light',
  contrast: 'Normal',
  isDark: () => false,
  isLight: () => true,
  isHighContrast: () => false,
  toggleMode: () => {},
  toggleContrast: () => {},
  classNames: () => '',
}

type ThemeProviderProps = {
  children: Node,
}

type ThemeProviderState = Theme

export const ThemeContext = React.createContext(defaultTheme)
export const ThemeConsumer = ThemeContext.Consumer

class Provider extends React.Component<ThemeProviderProps, ThemeProviderState> {

  constructor (props: ThemeProviderProps) {
    super(props)
    this.state = {
      mode: 'Light',
      contrast: 'Normal',
      isDark: this.isDark,
      isLight: this.isLight,
      isHighContrast: this.isHighContrast,
      toggleMode: this.toggleMode,
      toggleContrast: this.toggleContrast,
      classNames: this.classNames,
    }
  }

  isDark = () => {
    return this.state.mode === 'Dark' ? true : false
  }

  isLight = () => {
    return this.state.mode === 'Light' ? true : false
  }

  isHighContrast = () => {
    return this.state.contrast === 'High' ? true : false
  }

  toggleMode = () => {
    const mode: ThemeMode = this.state.mode === 'Light'
      ? 'Dark'
      : 'Light'
    this.setState({ mode })
  }

  toggleContrast = () => {
    const contrast: ThemeContrast = this.state.contrast === 'Normal'
      ? 'High'
      : 'Normal'
    this.setState({ contrast })
  }

  classNames = (styles: Object) => {
    const modeProp = `is${this.state.mode}Theme`
    const mode = styles[modeProp]
      ? styles[modeProp]
      : ''

    const contrastProp = `is${this.state.contrast}Contrast`
    const contrast = styles[contrastProp]
      ? styles[contrastProp]
      : ''

    return `${mode} ${contrast}`
  }

  render () {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    )
  }

}

export const ThemeProvider = Provider
