// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import SVGBrand from './assets/brand.svg'
import styles from './BrandLink.css'


type BrandLinkProps = {
  className?: string,
  to?: Function,
}

const BrandLink = ({ className, to }: BrandLinkProps) => {

  const restyle = className
    ? `${styles.component} ${className}`
    : styles.component

  return (
    <Link
      className={restyle}
      to={to || '/'}>
      <SVGBrand />
      <span className={styles.isHidden}>Samplar</span>
    </Link>
  )
}

export default BrandLink
