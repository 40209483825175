// @flow
import React from 'react'
import TextLink from '../components/TextLink'
import styles from './RegisterSuccess.css'


const RegisterSuccess = () => (
  <div className={styles.component}>
    <h1 className={styles.title}>
      Great!
    </h1>
    <p>
      Your registration is complete.
      Early spots are limited but we&#39;re looking forward to
      connecting and getting you onboard.
    </p>
    <div className={styles.actionsPrimary}>
      <TextLink
        type={'primary'}
        to={'/'}>
        Continue
      </TextLink>
    </div>
  </div>
)

export default RegisterSuccess
