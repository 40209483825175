// @flow
import React, { Component } from 'react'

import type {
  Node
} from 'react'


type NoScrollProps = {
  children?: Node,
}

class NoScroll extends Component<NoScrollProps> {

  componentDidMount () {
    // $FlowIgnore
    document.documentElement.classList.add('noScroll')
    // $FlowIgnore
    document.body.classList.add('noScroll')
  }

  componentWillUnmount () {
    // $FlowIgnore
    document.documentElement.classList.remove('noScroll')
    // $FlowIgnore
    document.body.classList.remove('noScroll')
  }

  render () {
    const { children } = this.props
    if (children) {
      return children
    }
    return null
  }

}

export default NoScroll
