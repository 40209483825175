import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Creators from './modules/creators'


const AppRoutes = () => (
  <Switch>
    <Route exact path='/' component={Creators} />
  </Switch>
)

export default AppRoutes
