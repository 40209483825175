// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './TextLink.css'

import type {
  Node
} from 'react'


export type TextLinkProps = {
  type: 'subtle' | 'primary' | 'callout' | 'disabled',
  children?: Node,
  className?: string,
  to: string,
}

const types = new Set(['subtle', 'primary', 'callout', 'disabled'])

const TextLink = ({
  children, type, className, to
}: TextLinkProps) => {

  let restyle = types.has(type)
    ? styles[type]
    : styles.primary

  restyle = className
    ? `${restyle} ${className}`
    : restyle

  return (
    <Link
      to={to}
      className={restyle}>
      {children}
    </Link>
  )
}

export default TextLink
