// @flow
import React from 'react'
import styles from './SectionTwo.css'


const SectionTwo = () => (
  <div className={styles.component}>
    <div className={styles.gradient}></div>
    <div className={styles.container}>
      <h1 className={styles.title}>
        3X<br />
        monetization<br />
        opportunities.
      </h1>
      <p className={styles.info}>
        <span className={styles.bolder}>Samplar</span>&nbsp;is&nbsp;
        <span className={styles.bolder}>subscriber-based across</span>&nbsp;all&nbsp;
        <span className={styles.bolder}>mediums</span>.
        <span className={styles.lgBreak}></span>
        <span className={styles.bolder}>&nbsp;Earn based on</span>&nbsp;your&nbsp;
        <span className={styles.bolder}>audience engagement</span>
        <span className={styles.lgBreak}></span>
        &nbsp;across&nbsp;
        <span className={styles.bolder}>all channels</span>&nbsp;and&nbsp;
        <span className={styles.bolder}>all devices</span>
        <span className={styles.lgBreak}></span>
        <span className={styles.bolder}>without worrying</span> about
        <span className={styles.lgBreak}></span>
        &nbsp;advertiser demonetization.
      </p>
    </div>
  </div>
)

export default SectionTwo
