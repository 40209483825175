// @flow
import React from 'react'
import TextLink from '../components/TextLink'
import styles from './Form.css'


type VerifyEmailProps = {
  email: string,
}

const VerifyEmailSuccess = ({ email }: VerifyEmailProps) => (
  <div className={styles.component}>
    <h1 className={styles.title}>
      Great!
    </h1>
    <p>
      Your email address &#39;{email}&#39; has been verified.
    </p>
    <div className={styles.actionsPrimary}>
      <TextLink
        type={'primary'}
        to={'/'}>
        Continue
      </TextLink>
    </div>
  </div>
)

export default VerifyEmailSuccess
