// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { NoScroll } from '@samplar/web-utils/scroll'
import Header from '../components/Header'
import SignInForm from '../forms/SignInForm'
import SignInSuccess from '../forms/SignInSuccess'
import styles from './Form.css'

import { USER_LOGIN } from '../mutations'


type SignedInResponse = {
  authLogin: {
    token: string,
    verified: boolean,
  }
}

class SignIn extends Component<{}> {

  onSignedIn = ({ authLogin }: SignedInResponse, client: Object) => {
    localStorage.setItem('auth-token', authLogin.token)
    client.resetStore()
  }

  render () {
    return (
      <div className={styles.component}>
        <NoScroll />
        <Header
          className={styles.header} />
        <div className={styles.container}>
          <h1 className={styles.title}>
            Sign In
            <span className={styles.subtle}>&nbsp;or&nbsp;</span>
            <Link
              to={'/register'}
              className={styles.subtle}>
              Sign Up
            </Link>
          </h1>
          <Mutation
            mutation={USER_LOGIN}
            errorPolicy='ignore'>
            {(mutate, props) => {

              if (props.data) {
                this.onSignedIn(props.data, props.client)
                return <SignInSuccess data={props.data} />
              }

              return (
                <SignInForm
                  mutate={mutate}
                  {...props} />
              )
            }}
          </Mutation>
        </div>
      </div>
    )
  }

}

export default SignIn
