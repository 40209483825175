// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BrandLink from '@samplar/web-ui/links/BrandLink'
import styles from './Footer.css'


class Footer extends Component<{}> {

  render () {
    const date = new Date()
    const year = date.getFullYear()
    return (
      <div className={styles.component}>
        <div className={styles.container}>
          <h3 className={styles.brand}>
            <span className={styles.hidden}>Samplar</span>
            <BrandLink className={styles.brandLogo} />
          </h3>

          <div className={styles.body}>
            <menu className={styles.menu}>
              <a
                href="mailto:hello@samplar.be"
                className={styles.link}>
                hello@samplar.be
              </a>
              {/* <Link
                to='/about'
                className={styles.link}>
                About
              </Link>
              <Link
                to='/contact'
                className={styles.link}>
                Contact
              </Link>
              <Link
                to='/terms-conditions'
                className={styles.link}>
                Terms &amp; Conditions
              </Link>
              <Link
                to='/privacy-policy'
                className={styles.link}>
                Privacy Policy
              </Link> */}
            </menu>
            <span className={styles.copyright}>
              © {year} SMPLR LTD
            </span>
          </div>
        </div>
      </div>
    )
  }

}

export default Footer
