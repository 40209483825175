// @flow
import React, { Component } from 'react'
import { Form } from '@samplar/web-form'
import TextField from '@samplar/web-ui/inputs/TextField'
import LoaderAnimation from '@samplar/web-ui/loaders/CircleLoader'
import TextButton from '../components/TextButton'
import TextLink from '../components/TextLink'
import Errors from '@samplar/web-ui/errors/FormErrors'
import styles from './Form.css'

import { validateBasic } from '@samplar/web-form/validators'


type SignInFormProps = {
  error?: Object,
  loading?: boolean,
  data?: Object,
  mutate: Function,
}

type SignInInput = {
  credential: string,
  password: string,
}

class SignInForm extends Component<SignInFormProps> {

  onSubmit = async ({ credential, password }: SignInInput) => {
    const credentials = {
      credential,
      password
    }
    try {
      const { mutate } = this.props
      await mutate({ variables: { credentials } })
    }
    catch (err) {
      console.log('GraphQL Error: ', err.message)
    }
  }

  render () {
    const { loading, error } = this.props

    return (
      <div className={styles.component}>

        <Form onSubmit={this.onSubmit}>
          {({ formApi, formState }) => {

            return (
              <div>
                <TextField
                  field={'credential'}
                  label={'Username'}
                  placeholder={'Username or Email'}
                  theme={'light'}
                  validate={validateBasic} />

                <TextField
                  field={'password'}
                  label={'password'}
                  placeholder={'password'}
                  theme={'light'}
                  type={'password'}
                  validate={validateBasic} />

                {formState.errors
                  && <Errors
                    errors={formState.errors}
                    message={'Sorry, there are a few changes required:'}
                    theme={'light'} />
                }

                {error
                  && error.graphQLErrors
                  && <Errors
                    errors={error.graphQLErrors}
                    message={'Your login failed, please check the following:'}
                    theme={'light'} />
                }

                {loading
                  && <LoaderAnimation
                    theme={'light'}
                    size={'small'} />
                }

                { ! loading
                  && <div className={styles.actionsPrimary}>
                    <TextButton
                      mode={'primary'}
                      onClick={formApi.submitForm}>
                      Login
                    </TextButton>
                    <TextLink
                      type={'callout'}
                      to={'/register'}>
                      Sign Up
                    </TextLink>
                  </div>
                }

                { ! loading
                  && <div className={styles.actionsSecondary}>
                    <TextLink
                      type={'subtle'}
                      to={'/forgotten-password'}>
                      Forgotten password?
                    </TextLink>
                  </div>
                }
              </div>
            )
          }}

        </Form>
      </div>
    )
  }

}

export default SignInForm
