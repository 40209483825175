// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import BrandLink from '@samplar/web-ui/links/BrandLink'
import BackButton from '@samplar/web-ui/buttons/BackButton'
import CloseButton from '@samplar/web-ui/buttons/CloseButton'
import styles from './Header.css'

import type {
  Location,
  RouterHistory
} from 'react-router-dom'


type HeaderProps = {
  history: RouterHistory,
  location: Location,
}

class Header extends Component<HeaderProps> {

  isModal (): boolean {
    const { location } = this.props
    if (location
        && location.state
        && location.state.modal) {
      return true
    }
    return false
  }

  canGoBack (): boolean {
    const { history } = this.props
    return history.length > 0 ? true : false
  }

  goBack = () => {
    if (this.isModal()) {
      this.props.history.goBack()
      return
    }
    this.props.history.push('/')
  }

  render () {
    return (
      <div className={styles.component}>
        {this.canGoBack()
          && <BackButton
            className={styles.back} />
        }
        <BrandLink
          className={styles.brand}/>
        <CloseButton
          className={styles.close}
          onClick={this.goBack} />
      </div>
    )
  }

}

export default withRouter(Header)
