// @flow
import React, { Component } from 'react'
import { Form } from '@samplar/web-form'
import TextField from '@samplar/web-ui/inputs/TextField'
import TextButton from '../components/TextButton'
import Errors from '@samplar/web-ui/errors/FormErrors'
import LoaderAnimation from '@samplar/web-ui/loaders/CircleLoader'
import { validateBasic, validateEmail } from '@samplar/web-form/validators'
import styles from './RegisterForm.css'


type RegisterFormProps = {
  onValidated: Function,
  message: string,
  status: string,
}

class RegisterForm extends Component<RegisterFormProps> {

  onSubmit = async ({ email, name }) => {
    const { onValidated } = this.props
    onValidated({
      EMAIL: email,
      NAME: name,
    })
  }

  render () {
    const { status } = this.props
    return (
      <div className={styles.component}>
        <p>
          For early access to our private beta.
          Early spots are limited but if you're a creator
          we&#39;d love to make contact to partner with you.
        </p>
        <Form onSubmit={this.onSubmit}>
          {({ formApi, formState }) => {
            return (
              <div>
                <TextField
                  field={'name'}
                  label={'Name'}
                  placeholder={'Name'}
                  theme={'light'}
                  validate={validateBasic} />

                <TextField
                  field={'email'}
                  label={'Email'}
                  placeholder={'Email'}
                  theme={'light'}
                  validate={validateEmail} />

                {formState.errors
                  && <Errors
                    errors={formState.errors}
                    message={'Sorry, there are a few changes required:'}
                    theme={'light'} />
                }

                {status === 'sending'
                  && <LoaderAnimation
                    theme={'light'}
                    size={'small'} />
                }

                {status !== 'sending'
                  && <div className={styles.actionsPrimary}>
                    <TextButton
                      type={'primary'}
                      mode={'callout'}
                      onClick={() => {}}>
                      Register
                    </TextButton>
                  </div>
                }
              </div>
            )
          }}
        </Form>
      </div>
    )
  }

}

export default RegisterForm
