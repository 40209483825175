// @flow
import React, { Component } from 'react'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import { Mutation } from 'react-apollo'
import { NoScroll } from '@samplar/web-utils/scroll'
import LoaderAnimation from '@samplar/web-ui/loaders/CircleLoader'
import Header from '../components/Header'
import MutateOnMount from '../components/MutateOnMount'
import VerifyEmailSuccess from '../forms/VerifyEmailSuccess'
import VerifyEmailFailure from '../forms/VerifyEmailFailure'
import styles from './Form.css'

import { VERIFY_EMAIL } from '../mutations'

import type {
  Location
} from 'react-router-dom'


type VerifyEmailProps = {
  location: Location,
}


class VerifyEmail extends Component<VerifyEmailProps> {

  render () {
    const { location } = this.props
    const { token } = queryString.parse(location.search)

    return (
      <div className={styles.component}>
        <NoScroll />
        <Header
          className={styles.header} />
        <div className={styles.container}>
          <h1 className={styles.title}>
            Verify Email
          </h1>
          <Mutation
            mutation={VERIFY_EMAIL}
            variables={{ token: { token } }}
            errorPolicy='ignore'>
            {(mutate, { data, error, loading }) => {

              if (data) {
                return <VerifyEmailSuccess {...data.userVerifyEmail} />
              }

              if (error) {
                return <VerifyEmailFailure />
              }

              if (loading) {
                return <LoaderAnimation
                  theme={'light'}
                  size={'small'} />
              }

              return (
                <div>
                  <MutateOnMount mutate={mutate} />
                </div>
              )
            }}
          </Mutation>
        </div>
      </div>
    )
  }

}

export default withRouter(VerifyEmail)
