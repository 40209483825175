// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { NoScroll } from '@samplar/web-utils/scroll'
import Header from '../components/Header'
import SignUpForm from '../forms/SignUpForm'
import SignUpSuccess from '../forms/SignUpSuccess'
import styles from './Form.css'

import { USER_REGISTER } from '../mutations'


class SignUp extends Component<{}> {

  render () {
    return (
      <div className={styles.component}>
        <NoScroll />
        <Header
          className={styles.header} />
        <div className={styles.container}>
          <h1 className={styles.title}>
            <Link
              to={'/login'}
              className={styles.subtle}>
              Sign In
            </Link>
            <span className={styles.subtle}>&nbsp;or&nbsp;</span>
            Sign Up
          </h1>
          <Mutation
            mutation={USER_REGISTER}
            errorPolicy='ignore'>
            {(mutate, props) => {

              if (props.data) {
                return <SignUpSuccess data={props.data} />
              }

              return (
                <SignUpForm
                  mutate={mutate}
                  {...props} />
              )
            }}
          </Mutation>
        </div>
      </div>
    )
  }

}

export default SignUp
