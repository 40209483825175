// @flow
import React from 'react'
import Link from '@samplar/web-ui/links/TextLink'
import styles from './TextLink.css'

import type {
  ActionLinkProps
} from '@samplar/web-ui/links/ActionLink'


const TextLink = (props: ActionLinkProps) => {

  return (
    <Link
      className={styles[props.type]}
      {...props} />
  )
}

export default TextLink
