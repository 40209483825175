// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BrandLink from '@samplar/web-ui/links/BrandLink'
import styles from './Header.css'


class Header extends Component<{}> {

  render () {
    return (
      <div className={styles.component}>
        <div className={styles.container}>
          <h3 className={styles.brand}>
            <span className={styles.hidden}>Samplar</span>
            <BrandLink className={styles.brandLogo} />
          </h3>
          <menu className={styles.menu}>
            <Link
              to='/register'
              className={styles.link}>
              Register
            </Link>
          </menu>
        </div>
      </div>
    )
  }

}

export default Header
