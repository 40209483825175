// @flow
import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import Routes from './AppRoutes'
import Footer from './modules/footer'
import Header from './modules/header'
import styles from './App.css'


class App extends Component<{}> {

  render () {
    return (
      <div className={styles.component}>
        <Header />
        <Routes />
        <Footer />
      </div>
    )
  }

}

export default hot(module)(App)
