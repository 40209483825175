// @flow
import React from 'react'
import CalloutButton from './CalloutButton'
import styles from './SectionFive.css'


const SectionFive = () => (
  <div className={styles.component}>
    <div className={styles.container}>
      <h1 className={styles.title}>
        Start now.
      </h1>
      <p className={styles.info}>
        Bloggers, Vloggers + Podcasters sign-up&nbsp;
        <span className={styles.lgBreak}></span>
        to help start and shape something new.
      </p>
      <CalloutButton
        to='/register'>
        Create your profile
      </CalloutButton>
    </div>
  </div>
)

export default SectionFive
