// @flow
import smoothscroll from 'smoothscroll'


export {
  default as NoScroll
} from './NoScroll'

export {
  default as ScrollToTopOnMount
} from './ScrollToTopOnMount'


export const smoothScrollTo = (x: number = 0, y?: number = 0) =>
  smoothscroll(x, y)
