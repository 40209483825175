// @flow
import React from 'react'
import styles from './CloseButton.css'


type CloseButtonProps = {
  className?: string,
  onClick: Function,
}

const CloseButton = ({ className, onClick }: CloseButtonProps) => {

  let restyle = className
    ? `${styles.component} ${className}`
    : styles.component

  onClick = onClick
    ? onClick
    : () => {}

  return (
    <button
      onClick={onClick}
      className={restyle}>
      <span className={styles.hidden}>Close</span>
      <span>✕</span>
    </button>
  )
}

export default CloseButton
