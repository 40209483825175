// @flow
import React, { Component } from 'react'

import type {
  Node
} from 'react'


type ScrollToTopOnMountProps = {
  children?: Node,
}

class ScrollToTopOnMount extends Component<ScrollToTopOnMountProps> {

  componentDidMount () {
    if (window && window.scrollTo) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    const { children } = this.props

    if (children) {
      //return React.Children.only(children)
      return children
    }

    return null
  }

}

export default ScrollToTopOnMount
