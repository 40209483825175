// @flow
import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { NoScroll } from '@samplar/web-utils/scroll'
import Header from '../components/Header'
import ForgottenPasswordForm from '../forms/ForgottenPasswordForm'
import ForgottenPasswordSuccess from '../forms/ForgottenPasswordSuccess'
import styles from './Form.css'

import { FORGOTTEN_PASSWORD } from '../mutations'


class ForgottenPassword extends Component<{}> {

  render () {
    return (
      <div className={styles.component}>
        <NoScroll />
        <Header
          className={styles.header} />
        <div className={styles.container}>
          <h1 className={styles.title}>
            Forgotten Password
          </h1>
          <Mutation
            mutation={FORGOTTEN_PASSWORD}
            errorPolicy='ignore'>
            {(mutate, props) => {

              if (props.data) {
                return <ForgottenPasswordSuccess data={props.data} />
              }

              return (
                <ForgottenPasswordForm
                  mutate={mutate}
                  {...props} />
              )
            }}
          </Mutation>
        </div>
      </div>
    )
  }

}

export default ForgottenPassword
