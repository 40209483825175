// @flow
import React, { Component } from 'react'
import { BasicText, asField } from '@samplar/web-form'
import styles from './TextField.css'


export type Props = {
  className?: string,
  field: string,
  fieldState: Object,
  initialValue?: string,
  label?: string,
  message?: string,
  placeholder?: string,
  required?: boolean,
  type?: string,
  theme?: 'light' | 'dark',
}

type State = {
  focussed: boolean,
  hasValue: boolean,
}

class TextField extends Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      focussed: false,
      hasValue: props.initialValue ? true : false,
    }
  }

  onFocus = () => {
    const { fieldState } = this.props
    this.setState({
      focussed: true,
      hasValue: fieldState.value ? true : false,
    })
  }

  onBlur = () => {
    const { fieldState } = this.props
    this.setState({
      focussed: false,
      hasValue: fieldState.value ? true : false,
    })
  }

  getComponentStyle () {
    const {
      className,
      fieldState,
      required,
      theme
    } = this.props

    let restyle = className
      ? `${styles.component} ${className}`
      : styles.component

    restyle = this.state.focussed
      ? `${restyle} ${styles.focussed}`
      : restyle

    restyle = this.state.hasValue
      ? `${restyle} ${styles.hasValue}`
      : restyle

    restyle = required && ! fieldState.error
      ? `${restyle} ${styles.required}`
      : restyle

    restyle = theme === 'light'
      ? `${restyle} ${styles.isLight}`
      : restyle

    restyle = fieldState.error
      ? `${restyle} ${styles.errored}`
      : restyle

    return restyle
  }

  render () {
    const {
      className,
      field,
      fieldState,
      label,
      message,
      theme,
      ...rest
    } = this.props

    const componentClasses = this.getComponentStyle()
    let footnote = message || ''

    return (
      <div className={componentClasses}>
        {label &&
          <label
            htmlFor={field}
            className={styles.label}>
            {label}
          </label>
        }
        <BasicText
          className={styles.input}
          field={field}
          fieldState={fieldState}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          {...rest} />
        <div className={styles.border}>
          <div className={styles.borderBackground}></div>
        </div>
        <p className={styles.footnote}>
          {footnote}
        </p>
      </div>
    )
  }

}

export default asField(TextField)
