// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import { ThemeProvider } from '@samplar/web-theme'
import { createStore } from '@samplar/web-store'
import createClient from './client'
import Main from './Main'
import '@samplar/web-css'


// $FlowIgnore
const rootEl = document.getElementById(__ROOT_NODE__)
const initialState = window.__INITIAL_STATE__
const store = createStore(initialState)
const client = createClient({
  uri: 'http://localhost:3001/graphql'
})

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <Component />
        </ThemeProvider>
      </ApolloProvider>
    </Provider>,
    // $FlowIgnore
    rootEl
  )
}

render(Main)
