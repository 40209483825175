// @flow
import React from 'react'
import TextLink from '../components/TextLink'
import styles from './Form.css'


const SignUpSuccess = () => (
  <div className={styles.component}>
    <h1 className={styles.title}>
      Great!
    </h1>
    <p>
      Your registration is complete and you&#39;re now a <i>Samplar</i>.
    </p>
    <div className={styles.actionsPrimary}>
      <TextLink
        type={'callout'}
        to={'/login'}>
        Login
      </TextLink>
    </div>
  </div>
)

export default SignUpSuccess
